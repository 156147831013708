const menuItems = () => {
  
  let menuItemHasChildren = document.querySelectorAll('.menu-item-has-children');

    menuItemHasChildren.forEach( (item) => {

      item.addEventListener('click', function(e) {

        item.classList.toggle('current-menu-item');

        if (!item.classList.contains('current-menu-item')) {
          item.classList.toggle('current-menu-ancestor');
        }

      })
    })
  
}

const toggleMenu = () => {
  var body = document.body;
  const toggleButton = document.querySelector('.smu-header__hamburger');

  if(toggleButton) {
    toggleButton.addEventListener('click', function() {
      body.classList.toggle('smu-header__nav--active');
      toggleButton.classList.toggle('active');
    })
  }

}

menuItems()
toggleMenu()


